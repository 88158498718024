import React from "react";
import Program from "../../../mui-v4/program/Program";
import Context from "../../../mui-v4/context/Context";
// import AirbnbPropTypes from "airbnb-prop-types";
import ProgramLayout from "../../../mui-v4/program/ProgramLayout";
import Header from "../../../mui-v4/header/Header";
import Menu from "../../../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import ProgramRow from "../../../mui-v4/program/ProgramRow";
import ProgramColumn from "../../../mui-v4/program/ProgramColumn";
import Group from "../../../mui-v4/group/Group";
import Field from "../../../mui-v4/field/Field";
import Table from "../../../mui-v4/table/Table";
import { showError } from "../../AppMessage";
import PropTypes from "prop-types";
import Validation from "../../../mui-v4/validation/Validation";
import print from "print-js";
import download from "downloadjs";

class Listar extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.shape({
      ca_campeonato: PropTypes.number,
      ca_nome: PropTypes.string,
    }),
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    const parameterMap = props.parameterMap;
    const editable = Validation.isDefined(parameterMap.ca_campeonato) ? false : true;
    const componentFocused = "mo_modalidade";

    console.log(parameterMap);

    /*
    const app = context.app;
    const appState = app.state;
    const usuario = appState.usuario;
    const campeonato = usuario.campeonato;

    let mo_modalidade = 0;
    let recordList = null;

    switch (campeonato) {
      case "cstp": mo_modalidade = 31; recordList = [{mo_modalidade: 31, mo_nome: "Trap50 OnLine"}]; break;
      case "cotp": mo_modalidade = 42; recordList = [{mo_modalidade: 42, mo_nome: "Trap100 Copa Oeste"}]; break;
      case "cc50": mo_modalidade = 31; recordList = [{mo_modalidade: 31, mo_nome: "Trap50 OnLine"}]; break;
      case "cctp": mo_modalidade = 2; recordList = [{mo_modalidade: 1, mo_nome: "CC100"}, {mo_modalidade: 2, mo_nome: "CC200"}, {mo_modalidade: 3, mo_nome: "CC DBL"}]; break;
      default: recordList = [];
    }
    */

    this.state = {
      ...this.state,
      editable: editable,
      record: {
        ca_campeonato: Validation.isDefined(parameterMap) && Validation.isDefined(parameterMap.ca_campeonato) ? parameterMap.ca_campeonato : null,
        ca_nome: Validation.isDefined(parameterMap) && Validation.isDefined(parameterMap.ca_nome) ? parameterMap.ca_nome : null,
        cam_campeonatoModalidade: null,
      },
      recordList: [],
      recordArray: [],
      componentFocused: componentFocused,
      success: false,
      result: null,
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onFechar = this.onFechar.bind(this);
    this.onAtualizar = this.onAtualizar.bind(this);
    this.onImprimir = this.onImprimir.bind(this);
    this.render = this.render.bind(this);
  }

  async onOpen() {
    super.onOpen();

    await this.onAtualizar();
  }

  onFechar() {
    this.close();
  }

  async onAtualizar() {
    try {
      const state = this.state;
      const record = state.record;
      const recordList = state.recordList;

      if (Validation.isDefined(record.ca_campeonato) && Validation.isDefined(record.cam_campeonatoModalidade)) {
        recordList.forEach((aRecord, aRecordIndex) => {
          if (aRecord.mo_modalidade === record.mo_modalidade) {
            record.mo_nome = aRecord.mo_nome;
          }
        });

        const response = await this.fetch("/resultado/acumuladoTrapIndividual/listar/carregarArray", record);
        const recordArrayNew = response.recordArray;

        let i = 0;
        for (i = 0; i < recordArrayNew.length; i++) {
          recordArrayNew[i].ati_melhoresResultados = recordArrayNew[i].ati_melhoresResultados.replaceAll(",", ", ");
        }

        this.setState((state, props) => {
          return {
            ...state,
            recordArray: recordArrayNew,
          };
        });
      }
    } catch (error) {
      showError(this, error);
    }
  }

  async onImprimir() {
    try {
      const state = this.state;
      const record = state.record;
      const recordList = state.recordList;

      recordList.forEach((aRecord, aRecordIndex) => {
        if (aRecord.mo_modalidade === record.mo_modalidade) {
          record.mo_nome = aRecord.mo_nome;
        }
      });

      const response = await this.fetch("/resultado/acumuladoTrapIndividual/listar/carregar", record);
      const pdfContent = response.pdfContent;

      print({
        printable: pdfContent,
        type: "pdf",
        base64: true,
        onError: (error) => {
          download("data:application/pdf;base64," + pdfContent, "portalDoAtirador_com_br.pdf", "application/pdf");
        },
      });

      this.close();
    } catch (error) {
      showError(this, error);
    }
  }

  render() {
    const state = this.state;
    const record = state.record;
    const recordArray = state.recordArray;
    const editable = state.editable;

    let columnArray = [
      <Table.ColumnNumber name="ati_classificacao" label="Pos" expanded={false} cellWidth={40} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} fontSize={"0.65rem"}/>,
      <Table.ColumnString name="as_nome" label="Atirador" expanded={false} cellWidth={160} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} fontSize={"0.65rem"}/>,
      // (<Table.ColumnString name="rti_nomeClube" label="Clube" expanded={true} cellWidth={250} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56}/>),
      <Table.ColumnString name="ati_nomeEquipe" label="Equipe" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} fontSize={"0.65rem"}/>,
      <Table.ColumnString name="ati_nomeClasse" label="Classe" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} fontSize={"0.65rem"}/>,
      <Table.ColumnNumber name="ati_somaMelhoresResultados" label="Soma" expanded={false} cellWidth={70} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} fontSize={"0.65rem"}/>,
      <Table.ColumnString name="ati_melhoresResultados" label="Melhores Resultados" expanded={true} cellWidth={180} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} fontSize={"0.65rem"}/>,
    ];

    return (
      <ProgramLayout program={this} width="1152px" height="maximum">
        <Header avatar="M" caption={"Acumulado do Trap Individual"} description={"Exibe o resultado do Trap Individual"} toolbar={<Menu.IconButton name="fechar" icon={<CloseIcon />} text="Fechar" onClick={this.onFechar} />} />
        <ProgramRow>
          <ProgramColumn width="400px" expanded={false}>
            <Group name="filtro" caption="Filtro de pesquisa" expanded={false} collapsable={false} collapsed={false} toolbar={null} menu={null}>
              <Field.List>
                <Field.List.Row>
                  <Field.Reference url={"/campeonato/referenciar"} urlParameterMap={{}} onChange={null} columnArray={[<Table.ColumnNumber name="ca_campeonato" label="Código" expanded={false} cellWidth={100} cellHeight={40} fixedCellWidth={80} fixedCellHeight={56} thousandSeparator="" decimalScale={0} />, <Table.ColumnString name="ca_nome" label="Descrição" expanded={true} cellWidth={100} cellHeight={40} fixedCellWidth={100} fixedCellHeight={56} />]}>
                    <Field.Input.Number name="ca_campeonato" record={record} label="Código" prefix="" thousandSeparator="" decimalScale={0} disabled={!editable} />
                    <Field.Input.String name="ca_nome" record={record} label="Campeonato" disabled={!editable} />
                  </Field.Reference>
                </Field.List.Row>
                <Field.List.Row>
                  <Field.Combo name="cam_campeonatoModalidade" caption="mo_nome" record={record} url={"/resultado/acumuladoTrapIndividual/listar/carregarModalidades"} urlParameterMap={this.props.parameterMap} label="Modalidade" onChange={this.onAtualizar} />
                </Field.List.Row>
              </Field.List>
            </Group>
          </ProgramColumn>
          <ProgramColumn width="400px" expanded={true}>
            <Table
              name="table"
              caption="Acumulado do Trap Individual"
              collapsable={false}
              collapsed={false}
              expanded={true}
              recordArray={recordArray}
              selectionType="none"
              selectionColumnName="bSelected"
              toolbar={
                <React.Fragment>
                  <Menu.IconButton name="imprimir" icon={<PrintIcon />} text="Imprimir" onClick={this.onImprimir} />
                </React.Fragment>
              }
              menu={null}
              columnArray={columnArray}
              fixedRowCount={1}
              fixedColumnCount={1}
            />
          </ProgramColumn>
        </ProgramRow>
      </ProgramLayout>
    );
  }
}

export default Listar;
