import React from 'react';
import Validation from '../validation/Validation';
import TableCellHeaderString from './TableCellHeaderString';
import TableCellString from './TableCellString';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';

const cellRenderer = ((props) => {
  return (
    <TableCellString align='left' fontSize={props.column.fontSize} {...props}/>
  );
});

const fixedCellRenderer = ((props) => {
  return (
    <TableCellHeaderString align='left' {...props}/>
  );
});

const sort = (({
    column,
    record1,
    record2,
  }) => {

  const row1 = record1;
  const row2 = record2;

  const columnName = column.name;
  const value1 = row1[columnName];
  const value2 = row2[columnName];
  const value1IsString = Validation.isString(value1);
  const value2IsString = Validation.isString(value2);

  if (!(value1IsString)) {
    return -1;
  }
  if (!(value2IsString)) {
    return 1;
  }

  return value1.localeCompare(value2, 'pt-br', {numeric: true});
});

const search = (({
    column,
    record,
    searchString,
  }) => {

  const row = record;
  const columnName = column.name;
  const value = row[columnName];
  const valueIsString = Validation.isString(value);
  const valueString = (valueIsString ? value : '');
  const valueStringLowerCase = valueString.toLowerCase();
  const searchStringLowerCase = searchString.toLowerCase();

  return (valueStringLowerCase.indexOf(searchStringLowerCase) !== -1);
});

class TableColumnString extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
    cellWidth: PropTypes.number.isRequired,
    cellHeight: PropTypes.number.isRequired,
    cellRenderer: PropTypes.func.isRequired,
    fixedCellWidth: PropTypes.number.isRequired,
    fixedCellHeight: PropTypes.number.isRequired,
    fixedCellRenderer: PropTypes.func.isRequired,
    sort: PropTypes.func,
    search: PropTypes.func,

    fontSize: PropTypes.string,

    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    name: null,
    label: null,
    expanded: null,
    cellWidth: null,
    cellHeight: null,
    cellRenderer: cellRenderer,
    fixedCellWidth: null,
    fixedCellHeight: null,
    fixedCellRenderer: fixedCellRenderer,
    sort: sort,
    search: search,

    fontSize: null,

    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  render() {
    return null;
  }

}

export default TableColumnString;
