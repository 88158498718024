import React from "react";
import PropTypes from "prop-types";
import Validation from "../validation/Validation";
import TableCellAlign from "./TableCellAlign";
import TableCell from "./TableCell";
import Highlighter from "react-highlight-words";
// import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

/*
const styles = ((theme) => {
  return {
    tableCellStringLeft: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
    },
    tableCellStringCenter: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
    },
    tableCellStringRight: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'right',
      /* direction: 'rtl', * /
    },
  };
});
*/

class TableCellNumber extends React.Component {
  static propTypes = {
    parent: PropTypes.any.isRequired,
    isVisible: PropTypes.any.isRequired,
    isScrolling: PropTypes.any.isRequired,
    rowIndex: PropTypes.any.isRequired,
    columnIndex: PropTypes.any.isRequired,
    // key: PropTypes.any,
    style: PropTypes.any.isRequired,
    keyValue: PropTypes.any.isRequired,
    table: PropTypes.object.isRequired,
    recordIndex: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    align: PropTypes.oneOf(Object.values(TableCellAlign)).isRequired,

    fontSize: PropTypes.string,

    decimalScale: PropTypes.number.isRequired,
    thousandSeparator: PropTypes.string.isRequired,

    children: PropTypes.node,
  };

  static defaultProps = {
    parent: null,
    isVisible: null,
    isScrolling: null,
    rowIndex: null,
    columnIndex: null,
    // key: null,
    style: null,
    keyValue: null,
    table: null,
    recordIndex: null,
    onClick: null,
    align: null,

    fontSize: null,

    decimalScale: null,
    thousandSeparator: null,

    children: null,
  };

  constructor(props) {
    super(props);

    this.formatter = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: props.decimalScale,
      maximumFractionDigits: props.decimalScale,
    });

    this.render = this.render.bind(this);
  }

  render() {
    const {
      theme,
      classes,
      parent,
      isVisible,
      isScrolling,
      rowIndex,
      columnIndex,
      // key,
      style,
      keyValue,
      table,
      recordIndex,
      onClick,
      align,

      fontSize,

      decimalScale,
      thousandSeparator,

      children,
      ...otherProps
    } = this.props;

    const props = table.props;
    const recordArray = props.recordArray;
    const record = recordArray[recordIndex];
    const state = table.state;
    const columnArray = state.columnArray;
    const column = columnArray[columnIndex];
    const columnName = column.name;
    const columnThousandSeparator = column.thousandSeparator;
    const value = record[columnName];

    const valueIsNumber = Validation.isNumber(value);
    const valueFormatted = valueIsNumber ? this.formatter.format(value) : "";
    const valueString =
      columnThousandSeparator === "."
        ? valueFormatted
        : valueFormatted.replace(".", columnThousandSeparator);

    const searchString = state.searchString;
    const searchStringIsEmpty = Validation.isEmpty(searchString);

    var className = null;

    switch (align) {
      case TableCellAlign.Left:
        // className = classes.tableCellStringLeft;
        className = (theme) => ({
          flex: "1 1 auto",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
        });
        break;
      case TableCellAlign.Center:
        // className = classes.tableCellStringCenter;
        className = (theme) => ({
          flex: "1 1 auto",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
        });
        break;
      case TableCellAlign.Right:
        // className = classes.tableCellStringRight;
        className = (theme) => ({
          flex: "1 1 auto",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "right",
          /* direction: 'rtl', */
        });
        break;
      default:
        className = null;
        break;
    }

    return (
      <TableCell
        parent={parent}
        isVisible={isVisible}
        isScrolling={isScrolling}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        key={keyValue}
        style={style}
        table={table}
        keyValue={keyValue}
        onClick={onClick}

        fontSize={fontSize}
        
        {...otherProps}
      >
        <Tooltip title={valueString}>
          <Box /* className={className} */ sx={className}>
            {searchStringIsEmpty === true && valueString}
            {searchStringIsEmpty !== true && (
              <Highlighter
                autoEscape={true}
                textToHighlight={valueString}
                searchWords={[searchString]}
              />
            )}
          </Box>
        </Tooltip>
      </TableCell>
    );
  }
}

// export default withStyles(styles, {withTheme: true})(TableCellNumber);
export default TableCellNumber;
