import React from 'react';
import Validation from '../validation/Validation';
import TableCellHeaderString from './TableCellHeaderString';
import TableCellNumber from './TableCellNumber';
import PropTypes from 'prop-types';
// import AirbnbPropTypes from 'airbnb-prop-types';

const cellRenderer = ((props) => {
  const column = props.column;
  const decimalScale = column.decimalScale;
  const thousandSeparator = column.thousandSeparator;

  return (
    <TableCellNumber align='right' fontSize={props.column.fontSize} decimalScale={decimalScale} thousandSeparator={thousandSeparator} {...props}/>
  );
});

const fixedCellRenderer = ((props) => {
  return (
    <TableCellHeaderString align='right' {...props}/>
  );
});

const sort = (({
    column,
    record1,
    record2,
  }) => {

  const row1 = record1;
  const row2 = record2;

  const columnName = column.name;
  const value1 = row1[columnName];
  const value2 = row2[columnName];
  const value1IsNumber = Validation.isNumber(value1);
  const value2IsNumber = Validation.isNumber(value2);

  if (!(value1IsNumber)) {
    return -1;
  }
  if (!(value2IsNumber)) {
    return 1;
  }

  return value1 - value2;
});

const search = (({
    column,
    record,
    searchString,
  }) => {

  const row = record;
  const columnName = column.name;
  const columnThousandSeparator = column.thousandSeparator;
  const value = row[columnName];

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: column.decimalScale,
    maximumFractionDigits: column.decimalScale,
  });

  const valueIsNumber = Validation.isNumber(value);
  const valueFormatted = (valueIsNumber ? formatter.format(value) : '');
  const valueString = (columnThousandSeparator === '.' ? valueFormatted : valueFormatted.replace('.', columnThousandSeparator));

  const valueStringLowerCase = valueString.toLowerCase();
  const searchStringLowerCase = searchString.toLowerCase();

  return (valueStringLowerCase.indexOf(searchStringLowerCase) !== -1);
});

class TableColumnNumber extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
    cellWidth: PropTypes.number.isRequired,
    cellHeight: PropTypes.number.isRequired,
    cellRenderer: PropTypes.func.isRequired,
    fixedCellWidth: PropTypes.number.isRequired,
    fixedCellHeight: PropTypes.number.isRequired,
    fixedCellRenderer: PropTypes.func.isRequired,
    sort: PropTypes.func,
    search: PropTypes.func,

    fontSize: PropTypes.string,

    decimalScale: PropTypes.number.isRequired,
    thousandSeparator: PropTypes.string.isRequired,

    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    name: null,
    label: null,
    expanded: null,
    cellWidth: null,
    cellHeight: null,
    cellRenderer: cellRenderer,
    fixedCellWidth: null,
    fixedCellHeight: null,
    fixedCellRenderer: fixedCellRenderer,
    sort: sort,
    search: search,

    fontSize: null,

    decimalScale: null,
    thousandSeparator: null,

    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.render = this.render.bind(this);
  }

  render() {
    return null;
  }

}

export default TableColumnNumber;
